<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>House Bank Accounts</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/banking/new-housebank-accounts">
            <v-icon left dark>mdi-plus</v-icon> New
          </v-btn>
        </v-toolbar>

        <v-card-text>
           <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="Housebanks"
                :search="search"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    Housebanks: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Country", value: "country.name" },
      { text: "Bank Name", value: "AcctName" },
      { text: "Account", value: "Account" }
    ]
  }),
  methods: {  
       getHouseBanks() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/house_bank`)
        .then(res => {
          self.Housebanks = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }

  },
  created() {
    this.getHouseBanks();
  }
};
</script>